const LIMIT_LINES = 3;

function isEnableAccordion(element) {
  const contentElement = element.querySelector('.l-patientBefore__listText');
  const lineHeight = parseFloat(
    window.getComputedStyle(contentElement).getPropertyValue('line-height'),
  );

  return Math.ceil(contentElement.scrollHeight / lineHeight) > LIMIT_LINES;
}

function setDataLimitheight(element) {
  const contentElement = element.querySelector('.l-patientBefore__listText');
  const lineHeight = parseFloat(
    window.getComputedStyle(contentElement).getPropertyValue('line-height'),
  );

  contentElement.dataset.limitHeight = `${lineHeight * LIMIT_LINES}px`;
}

function initAccordion(elements) {
  elements.forEach((element) => {
    const buttonElement = element.querySelector('.l-patientBefore__acdIcon');
    const contentElement = element.querySelector('.l-patientBefore__listText');
    let isAnimate = false;

    contentElement.addEventListener('transitionend', (event) => {
      if (event.currentTarget === event.target) {
        if (element.classList.contains('is-open')) {
          // eslint-disable-next-line no-param-reassign
          event.target.style.height = '';
        }
        isAnimate = false;
      }
    });
    buttonElement.addEventListener('click', (event) => {
      event.preventDefault();

      if (isAnimate) {
        contentElement.style.height = element.classList.contains('is-open')
          ? contentElement.dataset.limitHeight
          : `${contentElement.scrollHeight}px`;
      } else {
        if (element.classList.contains('is-open')) {
          contentElement.style.height = `${contentElement.scrollHeight}px`;
          // eslint-disable-next-line no-unused-expressions
          contentElement.offsetHeight; // reflow
          contentElement.style.height = contentElement.dataset.limitHeight;
        } else {
          contentElement.style.height = contentElement.dataset.limitHeight;
          // eslint-disable-next-line no-unused-expressions
          contentElement.offsetHeight; // reflow
          contentElement.style.height = `${contentElement.scrollHeight}px`;
        }
        isAnimate = true;
      }
      if (element.classList.contains('is-open')) {
        element.classList.remove('is-open');
        contentElement.classList.remove('is-open');
      } else {
        element.classList.add('is-open');
        contentElement.classList.add('is-open');
      }
    });
  });
}

function setAccordion(elements) {
  elements.forEach((element) => {
    const contentElement = element.querySelector('.l-patientBefore__listText');

    setDataLimitheight(element);
    if (isEnableAccordion(element)) {
      element.classList.add('is-accordion');
      contentElement.style.height = element.classList.contains('is-open')
        ? ''
        : contentElement.dataset.limitHeight;
    } else {
      element.classList.remove('is-accordion');
      contentElement.style.height = '';
    }
  });
}

export default function patientAccordion() {
  const accordionElements = Array.from(
    document.querySelectorAll('.l-patientBefore__listItem'),
  ).filter((element) => {
    return (
      element.querySelector('.l-patientBefore__listText') !== null &&
      element.querySelector('.l-patientBefore__acdIcon') !== null
    );
  });

  initAccordion(accordionElements);

  new Promise((resolve) => {
    const { documentElement } = document;
    const hasLoadingClass = () => documentElement.classList.contains('wf-loading');

    if (hasLoadingClass()) {
      const observer = new MutationObserver(() => {
        if (!hasLoadingClass()) {
          observer.disconnect();
          resolve();
        }
      });

      observer.observe(documentElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
    } else {
      resolve();
    }
  }).then(() => {
    setAccordion(accordionElements);
  });
  window.addEventListener('resize', () => {
    setAccordion(accordionElements);
  });
}
