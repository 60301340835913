import scrollTarget from './scrollTarget';

const debounce = (func, wait = 1000) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(...args);
    }, wait);
  };
};

export default function form() {
  const debounceInvalidEvent = debounce(() => {
    const invalidElement = document.querySelector(':invalid:not(form)');

    if (invalidElement !== null) {
      scrollTarget(invalidElement);
    }
  }, 0);

  document.querySelectorAll('form [required]').forEach((formElement) => {
    formElement.addEventListener('invalid', debounceInvalidEvent);
  });
}
