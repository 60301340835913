function sliderThumbnail() {
  const blockGalleryElements = document.querySelectorAll('.l-floorContentsSlider');
  if (blockGalleryElements === null) return;

  blockGalleryElements.forEach((element) => {
    // eslint-disable-next-line no-undef
    const galleryThumbs = new Swiper(element.querySelector('.l-floorContentsSliderThumbs'), {
      loop: false,
      slidesPerView: 'auto',
    });

    // eslint-disable-next-line no-undef, no-new
    new Swiper(element.querySelector('.l-floorContentsSliderMain'), {
      autoHeight: true,
      effect: 'fade',
      slidesPerView: 1,
      speed: 1000,
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  });
}

export default function slider() {
  sliderThumbnail();
}
