export default function fixedheader() {
  const globalNavigationElement = document.querySelector('.l-globalNavigation');
  const parentElement = globalNavigationElement ? globalNavigationElement.parentNode : null;
  let isFixed = false;

  if (parentElement === null) return;

  window.addEventListener('scroll', () => {
    if (parentElement.getBoundingClientRect().top < 0) {
      if (!isFixed) {
        globalNavigationElement.classList.add('fixed');
        isFixed = true;
      }
    } else if (isFixed) {
      globalNavigationElement.classList.remove('fixed');
      isFixed = false;
    }
  });
}
