export default function toggleContent() {
  $(document).ready(function () {
    $('[data-target]').on('click', function () {
      const target = $(this).attr('data-target');

      $('[data-target]._active').removeClass('_active');
      if (!$(this).hasClass('_active')) {
        $(this).addClass('_active');
      }

      $('[data-content]._active').removeClass('_active');
      $(`[data-content="${target}"]`).addClass('_active');
    });

    if($('.p-contact__main').length){
      $('[data-accordion-target]').on('click', function () {
        if($('.p-contact__faq.is-open').length){
          $('.p-contact__main').addClass('_showed');
        } else {
          $('.p-contact__main').removeClass('_showed');
        }
      });
    }
  });
}
