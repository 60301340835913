export default function hamburgerMenu() {
  const hamburgerMenuElement = document.querySelector('.js-hamburger');
  const ariaControls =
    hamburgerMenuElement.length === 0 ? null : hamburgerMenuElement.getAttribute('aria-controls');
  const ariaControlsElement = ariaControls ? document.getElementById(ariaControls) : null;

  if (ariaControlsElement === null) return;

  hamburgerMenuElement.addEventListener('click', (event) => {
    if (hamburgerMenuElement.getAttribute('aria-expanded') === 'true') {
      hamburgerMenuElement.setAttribute('aria-expanded', 'false');
      ariaControlsElement.setAttribute('aria-hidden', 'true');
      ariaControlsElement.classList.remove('is-open');
    } else {
      hamburgerMenuElement.setAttribute('aria-expanded', 'true');
      ariaControlsElement.setAttribute('aria-hidden', 'false');
      ariaControlsElement.classList.add('is-open');
    }

    event.preventDefault();
  });
  ariaControlsElement.addEventListener('click', (event) => {
    if (event.target.closest('[data-hamburger-close]')) {
      hamburgerMenuElement.click();
      event.preventDefault();
    } else if (event.target.closest('a')) {
      hamburgerMenuElement.click();
    }
  });
}
