export default function floorNav() {
  const floorNavElement = document.querySelector('.js-floorNav');
  if (!floorNavElement) return;

  const observeElements = document.querySelectorAll('.js-observe-element');

  const options = {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0,
  };

  const toggleCurrentClass = (element) => {
    const currentActiveElement = document.querySelector('.js-link-element.current');
    if (currentActiveElement !== null) {
      currentActiveElement.classList.remove('current');
    }
    const nextActiveElement = document.querySelector(`a[href='#${element.id}'].js-link-element`);
    nextActiveElement.classList.add('current');
  };

  const checkViewingSection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        toggleCurrentClass(entry.target);
      }
    });
  };

  const sectionObserver = new IntersectionObserver(checkViewingSection, options);

  observeElements.forEach((observeElement) => {
    sectionObserver.observe(observeElement);
  });

  const spOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  const switchNav = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        floorNavElement.classList.add('_show');
      } else {
        floorNavElement.classList.remove('_show');
      }
    });
  };

  const navObserver = new IntersectionObserver(switchNav, spOptions);

  navObserver.observe(document.querySelector('.js-floorContents'));
}
