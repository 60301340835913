export default function accordion() {
  const accordionNodeList = document.querySelectorAll('.js-accordion, .c-acordion__title');

  accordionNodeList.forEach((accordionElement) => {
    const contentSelector = accordionElement.dataset.accordionTarget;
    const contentSelectorlement = contentSelector ? document.querySelector(contentSelector) : null;
    const isNextElement = contentSelectorlement === null;
    const contentElement = isNextElement
      ? accordionElement.nextElementSibling
      : contentSelectorlement;
    let isAnimate = false;
    let storeHeight;

    contentElement.addEventListener('transitionend', (event) => {
      if (event.currentTarget === event.target) {
        // eslint-disable-next-line no-param-reassign
        event.target.style.display = '';
        // eslint-disable-next-line no-param-reassign
        event.target.style.height = '';
        isAnimate = false;
      }
    });
    accordionElement.addEventListener('click', (event) => {
      const childElement = contentElement;
      const stateTarget =
        accordionElement.dataset.accordionState === 'parent'
          ? accordionElement.parentNode
          : accordionElement;
      let elementHeight;

      event.preventDefault();

      if (isAnimate) {
        childElement.style.height = stateTarget.classList.contains('is-open')
          ? 0
          : `${storeHeight}px`;
      } else {
        childElement.style.display = 'block';
        elementHeight = childElement.getBoundingClientRect().height;
        if (stateTarget.classList.contains('is-open')) {
          childElement.style.height = `${elementHeight}px`;
          // eslint-disable-next-line no-unused-expressions
          childElement.offsetHeight; // reflow
          childElement.style.height = 0;
        } else {
          childElement.style.height = 0;
          // eslint-disable-next-line no-unused-expressions
          childElement.offsetHeight; // reflow
          childElement.style.height = `${elementHeight}px`;
        }
        storeHeight = elementHeight;
        isAnimate = true;
      }
      if (stateTarget.classList.contains('is-open')) {
        stateTarget.classList.remove('is-open');
        if (!isNextElement) {
          contentElement.classList.remove('is-open');
        }
      } else {
        stateTarget.classList.add('is-open');
        if (!isNextElement) {
          contentElement.classList.add('is-open');
        }
      }
    });
  });
}
