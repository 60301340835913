export default function doctorList() {
  const { documentElement } = document;

  documentElement.addEventListener('click', (event) => {
    const toggleElement = event.target.closest('.l-doctorList__detail');
    const excludeElement = event.target.closest('.l-doctorList__detailInner');

    if (toggleElement !== null && excludeElement === null) {
      toggleElement.classList.toggle('is-open');
    }
  });
}
