import 'what-input';
import './polyfill';
import hashScroll from './hashScroll';
import changeMetaViewport from './changeMetaViewport';
import fixedheader from './fixedheader';
import fixedMenu from './fixedmenu';
import hamburgerMenu from './hamburgerMenu';
import accordion from './accordion';
import patientAccordion from './patient-accordion';
import toggleContent from './toggleContent';
import modaalSetup from './modaalSetup';
import slider from './slider';
import floorNav from './floorNav';
import doctorList from './doctorList';
import form from './form';
import imageMap from './imageMap';
import fixedCureNavigation from './fixedCureNavigation';

hashScroll();
changeMetaViewport();
fixedheader();
fixedMenu();
hamburgerMenu();
accordion();
patientAccordion();
toggleContent();
modaalSetup();
slider();
floorNav();
doctorList();
form();
imageMap();
fixedCureNavigation();
