export default function fixedCureNavigation() {
  if($('.p-cure').length) {
    _scroll();
    window.addEventListener('scroll', function(){
      _scroll();
    });
  }
  function _scroll(e) {
    var fixedPoint = ($('.p-cure').offset().top - 64);
    var stop = document.documentElement.scrollTop || document.body.scrollTop;
    var h = Array.prototype.slice.call(document.getElementsByTagName('html'))[0];
    if (stop >= fixedPoint) {
      h.classList.add('-scrolled');
    } else {
      h.classList.remove('-scrolled');
    }
  }
}
