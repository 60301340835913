export default function fixedMenu() {
  const menuElement = document.querySelector('.js-fixedMenu');
  let isShowed = false;

  if (menuElement === null) return;

  window.addEventListener('scroll', () => {
    const scrollY = window.scrollY || window.pageYOffset;

    if (window.innerHeight < scrollY) {
      if (!isShowed) {
        menuElement.classList.add('show');
        isShowed = true;
      }
    } else if (isShowed) {
      menuElement.classList.remove('show');
      isShowed = false;
    }
  });
}
